import { useEffect, useState } from "react"
import "./comic.css"
import ChaptersIndex from "./components/chaptersIndex"
import NavMenu from "./components/navMenu"
import axiosApp from "../../common/api"
import GImg from "../../common/gImg"
import ClimbingBoxLoader from "react-spinners/ClimbingBoxLoader";
import Mailing from "../about/components/mailing"

export default function Comic() {
    const [comic, setComic] = useState()
    const [currChapter,setCurrChapter] = useState(0)

    useEffect(() => { 
    let currPath = window.location.href;
    let currComic = currPath.split("/");
    currComic = currComic[currComic.length -1];
    axiosApp
    .get("/comic/"+currComic, {})
    .then((response) => {
      console.log(response.data)
      setComic(response.data)
      let chapters = response.data.chapters;
      const temp_currChapter = chapters.findIndex(chapter => chapter.title === response.data.title);
        setCurrChapter(temp_currChapter)
    })
    .catch((error) => {
      console.log(error);
    });
    }, [])

    function delay(time) {
        return new Promise(resolve => setTimeout(resolve, time));
      }
      function refresh(){
        delay(500).then(() => window.location.reload())
      }
  return (
    <div className="comic">
      <div className="comic-details">
        {comic && comic.novel_title ? <NavMenu refresh={refresh} comic={comic} currChapter={currChapter}/> : ""}
        <h1>{comic && comic.novel_title ? comic.novel_title + ": " : ""}{comic ? comic.title : ""}</h1>
        <span className="comic-desc">{comic ? comic.desc : ""}</span>
        {comic && comic.reviews ?<span className="comic-desc"> reviewed by:
        <br/>
        {comic.reviews.map((review, index) => 
        <a href={review.link} target="_blank" rel="noreferrer" key={index}>
        &nbsp;{review.name}
        {comic.reviews.length !== 1 && index !== comic.reviews.length -1 ? "," : ""}
          </a>
        )} 
        </span>: ""}
      </div>
      <div className="comic-pages">
      {comic ? comic.pages.map((page, index) => (
        <GImg src={page} key={index} alt={"comic img"}/>
     )):<div className="spinner"><ClimbingBoxLoader color="#85c7ee" /></div>}
        <Mailing/>
      </div>
      <hr/>
      {comic && comic.novel_title ? <span>
      <div className="next-chapters">
       {comic?  <ChaptersIndex refresh={refresh} chapters={comic.chapters} currChapter={currChapter}/> : ""}
      </div>
      <div className="chapter-index">Index</div>
      </span> : ""}
    </div>
  )
}
