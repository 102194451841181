import "./popup.css";
import CloseIcon from '@mui/icons-material/Close';

export default function Popup(props) {
  return props.trigger ? (
    <div className="popup">
      <div
        className="popup-inner"
        style={{
          backgroundColor: "white"
        }}
      >
        {props.noClose !== true ? (
          <button
            className="close-btn"
            onClick={() => props.setTrigger(false)}
          >
            <CloseIcon/>
          </button>
        ) : (
          ""
        )}
        {props.children}
      </div>
    </div>
  ) : (
    ""
  );
}
