import { BrowserRouter, Routes, Route } from "react-router-dom";
import './App.css';
import Layout from "./components/common/layout/layout";
import Comics from "./components/pages/comics/comics";
import Chapters from "./components/pages/chapters/chapters";
import Comic from "./components/pages/comic/comic";
import Gallery from "./components/pages/gallery/gallery";
import About from "./components/pages/about/about";
import { useState, useEffect } from "react";
import axiosApp from "./components/common/api";

function App() {
  const [currPath, setCurrPath] = useState("")

  useEffect(() => { 
   const interval = setInterval(() => {
    axiosApp
    .get("/keep-alive", {})
    .then((response) => {
      // console.log(response.data)
    })
    .catch((error) => {
      console.log(error);
    });
  }, 60000);
return () => clearInterval(interval)
}, [])
  
  return (
    <>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout currPath={currPath} setCurrPath={setCurrPath}/>}>
          <Route index element={<Comics setCurrPath={setCurrPath}/>} />
          <Route path="chapters/:comic" element={<Chapters />} />
          <Route path="comic/:comic" element={<Comic />} />
          <Route path="gallery" element={<Gallery />} />
          <Route path="about" element={<About />} />
        </Route>
      </Routes>
    </BrowserRouter>
  </>
  );
}

export default App;
