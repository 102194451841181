import { useEffect, useState } from "react"
import "./chapters.css"
import WindowIcon from '@mui/icons-material/Window';
import { Link } from "react-router-dom";
import axiosApp from "../../common/api";
import GImg from "../../common/gImg";
import ClimbingBoxLoader from "react-spinners/ClimbingBoxLoader";
import Mailing from "../about/components/mailing";

export default function Chapters() {
  const [novel, setNovel] = useState()

  useEffect(() => { 
    let currPath = window.location.href;
    let currNovel = currPath.split("/");
    currNovel = currNovel[currNovel.length -1];
    axiosApp
    .get("/chapters/"+currNovel, {})
    .then((response) => {
      console.log(response.data)
      setNovel(response.data)
    })
    .catch((error) => {
      console.log(error);
    });
    

}, [])

 return (
    <div className="chapters">
      <div className="novel-details">
      <Link to={"/"}><WindowIcon /> </Link>
        <h1>{novel ? novel.title : ""}</h1>
        <span className="novel-desc">{novel ? novel.desc : ""}</span>
        {novel && novel.reviews ?<span className="comic-desc"> {novel.reviews.length >0 ? "reviewed by:" : ""}
        <br/>
        {novel.reviews.map((review, index) => 
        <a href={review.link} target="_blank" rel="noreferrer" key={index}>
        &nbsp;{review.name}
        {novel.reviews.length !== 1 && index !== novel.reviews.length -1 ? "," : ""}
          </a>
        )} 
        </span>: ""}
        {novel && novel.linktxt ?<span className="comic-desc"> 
        <a href={novel.link} target="_blank" rel="noreferrer"> {novel.linktxt}</a>        
        </span>: ""}
      </div>
      {novel ? <div className="novel-pages">
        <GImg className="cover-img" src={novel.cover} alt={"novel cover"}/>
      <br/>
      <span className="chapters-title">Chapters</span>
      <br/>
      {novel.chapters.map((chapter, index) => (
        !chapter.title.includes("coming soon") ? 
        <Link to={"/comic/"+chapter.title} key={index} >
        <p className="chapter-item">
          <GImg src={chapter.img} alt={"chapter img"}/>
          <span>{chapter.title}</span>
          </p>
          </Link>
        : 
        <p className="chapter-item no-link">
          <GImg src={chapter.img} alt={"chapter img"}/>
          <span>{chapter.title}</span>
          </p>
     ))}
     <Mailing/>
      </div> : <div className="spinner"><ClimbingBoxLoader color="#85c7ee" /></div>}
    </div>
  )
}
