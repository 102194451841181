import MailchimpSubscribe from "react-mailchimp-subscribe";
import CustomForm from "./customform";

// sets up mailing component using mail chimp subscribe, uses custom form to create a custom form for it
export default function Mailing() {
    const u = "5df7804ee0c6fb59c8bdf4d1a";
    const id = "d58b7efbcb";
    const postUrl = `https://gmail.us20.list-manage.com/subscribe/post?u=${u}&id=${id}`;

  return (
    <div>
      <table className="mailing">
          <tbody>
              <tr>
                <td>
                    <br/>
                </td>
                <td className="mailForm">
                <MailchimpSubscribe 
                url={postUrl}
                render={({ subscribe, status, message }) => (
                    <CustomForm
                        status={status} 
                        message={message}
                        onValidated={formData => subscribe(formData)}
                    />
                )}
                />
                </td>
              </tr>
          </tbody>
        </table>
    </div>
  )
}