import { Outlet } from "react-router-dom";
import './layout.css';
import { Link } from "react-router-dom";
import {  useEffect } from 'react';

export default function Layout({currPath, setCurrPath}) {
  
  useEffect(() => { 
      var tempPath = window.location.href; 
      setCurrPath(tempPath.split("/")[tempPath.split("/").length-1])
  }, [])

  return (
    <div>
      <div className="header">
      <Link to="/" onClick={() => setCurrPath("")}> <h1><img src="/img/head_bird.jpg" alt="icon"/> Navy Bird</h1></Link>
        <h3>comics & illustrations</h3>
        <span className="navbar">
        <Link to="/" onClick={() => setCurrPath("")}>
          <span className={currPath === "" ? "selectedbtn navbtn" : "navbtn"}>comics</span>
          </Link>
          <Link to="/gallery" onClick={() => setCurrPath("gallery")}>
          <span className={currPath === "gallery" ? "selectedbtn navbtn" : "navbtn"}>gallery</span>
          </Link>
          <Link to="/about" onClick={() => setCurrPath("about")}>
          <span className={currPath === "about" ? "selectedbtn navbtn" : "navbtn"}>about</span>
          </Link>
          <a href="https://www.inprnt.com/gallery/navybird/" target="_blank" rel="noreferrer">
          <span className="navbtn">shop</span>
          </a>
        </span>
      </div>
      <Outlet/>
      <br/>
      <div className="footer">
        Navy Bird @ {new Date().getFullYear()}
      </div>
    </div>
  )
}
