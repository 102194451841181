import { Link } from "react-router-dom";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import MenuIcon from '@mui/icons-material/Menu';
import WindowIcon from '@mui/icons-material/Window';

export default function NavMenu({currChapter, comic, refresh}) {

  
  return (
    <span className="nav-menu">
        {comic && comic.novel_title? <span>
            {currChapter === 0 ? 
            <span style={{color: "gray"}}>
            <ArrowBackIosIcon />
            </span> : 
            <span style={{color: "black"}}>
            <Link onClick={() => refresh()} to={"/comic/"+comic.chapters[currChapter -1].title}>
            <ArrowBackIosIcon /></Link></span> }
            <Link to={"/chapters/"+comic.novel_title}><MenuIcon /></Link>
            {currChapter === comic.chapters.length -1 ? 
            <span style={{color: "gray"}}>
            <ArrowForwardIosIcon />
            </span> : 
            <span style={{color: "black"}}>
            <Link onClick={() => refresh()} to={"/comic/"+comic.chapters[currChapter +1].title}>
            <ArrowForwardIosIcon /></Link></span> }
            </span> :
            <Link to={"/"}><WindowIcon /> </Link>
            }
        </span>
  )
}
