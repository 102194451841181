import { useEffect, useState } from "react"
import ResponsiveGallery from "../../common/responsiveGallery/responsiveGallery"
import { useNavigate  } from "react-router-dom";
import axiosApp from "../../common/api";
import ClimbingBoxLoader from "react-spinners/ClimbingBoxLoader";

export default function Comics({setCurrPath}) {
    const [comics, setComics] = useState()
    const navigate = useNavigate();

    useEffect(() => { 
        axiosApp
              .get("/", {})
              .then((response) => {
                console.log(response.data)
                setComics(response.data)
              })
              .catch((error) => {
                console.log(error);
              });
    }, [])

    function onComicClick (comic)  {
        setCurrPath()
        navigate("/"+comic.type+"/"+comic.title)

    }
  return (
    <div>
      {comics  ? <ResponsiveGallery items={comics} clickFunc={onComicClick}/> : 
      <div className="spinner"><ClimbingBoxLoader color="#85c7ee" /></div>}
    </div>
  )
}
