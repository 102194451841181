import "./about.css"
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';
import AddLinkIcon from '@mui/icons-material/AddLink';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import { Link } from "react-router-dom";
import Mailing from "./components/mailing";

export default function About() {
  return (
    <div className="about">
      <span className="mailing-container">
        <Mailing/>
      </span>
      <img className="about-img" src="./img/about_bird.png" alt="about img"/>
    <br/>
      <span className="about-title">Hello, I'm Navy Bird!</span>
      <br/>
      <span className="about-desc">
      Won't you like to know something about me?
      I was born centuries ago, on a pirate's ship, or perhaps on a train,
      Though what does it matter? I make art and comics now. 
      Now I bring you fantastical stories, with larger-than-life people, places, and events,
      And I welcome you to my wonderland, hope you enjoy your stay.
      </span>
      <br/>
      <br/>
      <span>
      <span className="about-title">Published Work:
      <br/>
      <Link to="/chapters/Arlequino & Pierrot">
      • Arlequino & Pierrot, self published graphic novel, 2024
      </Link>
      <br/>
      • WIP Change - Wip Comics Anthology, 2024
      <br/>
      <Link to="/chapters/Still Earth">
      • Still Earth, self published graphic novel, 2023
      </Link>
      <br/>
      • Mythology - ComicsCG Anthology, 2023
      <br/>
      • WIPXL - Wip Comics Anthology, 2023
      <br/>
      • Inversion - Anthology, 2022
      <br/>
      • LuckyWIP - Wip Comics Anthology, 2022
      </span>
      </span>
      <br/>
      <br/>
      <span className="about-links">
      <span className="about-title">Contact me:</span>
        <br/>
      <a href="https://www.instagram.com/navy.bird.art/" target="_blank" rel="noreferrer">
        <InstagramIcon/>@navy.bird.art
        </a>
      <br/>
      <a href="https://www.twitter.com/NavyBirdArt/" target="_blank" rel="noreferrer">
        <TwitterIcon/>@NavyBirdArt
        </a>
      <br/>
      <a href="mailto: navybirdcomics@gmail.com" target="_blank" rel="noreferrer">
        <MailOutlineIcon/>navybirdcomics@gmail.com
        </a>
      <br/>
      <a href="https://linktr.ee/NavyBird" target="_blank" rel="noreferrer">
        <AddLinkIcon/>other links
        </a>
      </span>
      <br/>
      <br/>
      <span className="about-links">
      <span className="about-title">Press:
        <br/>
      <a href="https://midlifegamergeek.com/2023/06/28/comic-book-review-still-earth/" target="_blank" rel="noreferrer">
      • Review of Still Earth by Midlifegamergeek
        </a>
      <br/>
      <a href="https://awesomecomics.podbean.com/e/episode-416-the-changing-face-of-small-press-comics/" target="_blank" rel="noreferrer">
      • Review of Still Earth by AwesomeComicsPodcast
        </a>
        </span>
      </span>
      <br/>
      <span>
        <br/>
      <span className="about-title">Affiliations:</span>
      <br/>
      <a href="https://wipcomics.co.uk/" target="_blank" rel="noreferrer">
      WIP Comics 
      </a>
      <br/>
      <a href="https://comicscg.alilon.co.il/" target="_blank" rel="noreferrer">
      ComicsCG
      </a>
      </span>
      <br/>
      <br/>
      <span className="mailing-container-1">
        <Mailing/>
      </span>
    </div>
  )
}
