import './responsiveGallery.css'
import GImg from '../gImg'

export default function ResponsiveGallery({items, clickFunc}) {
  return (
    <div className='responsive-gallery'>
   {items ? items.map((item, index) => (
   <div className='gallery-item' key={index} onClick={() => clickFunc(item)}>
        <GImg src={item.img} alt="gallery img"/>
        <br/>
        <span className='item-title'>{item.title} </span>
    </div>
     )):""}
    </div>
  )
}
