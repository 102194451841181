import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation} from 'swiper';
import 'swiper/swiper.min.css';
import 'swiper/css/navigation';
import { Link } from "react-router-dom";
import GImg from '../../../common/gImg';

export default function ChaptersIndex({chapters, currChapter, refresh}) {
  return (
    <Swiper
    modules={[Navigation]}
    initialSlide = {currChapter}
    spaceBetween={10}
    slidesPerView={4}
    navigation
    onSlideChange={() => console.log('slide change')}
    onSwiper={(swiper) => console.log(swiper)}
  >
    {chapters.map((chapter, index) => (
        <SwiperSlide key={index} >
          {!chapter.title.includes("coming soon") ? 
           <Link to={"/comic/"+chapter.title} onClick={() => refresh()}> 
           <GImg className={index === currChapter ? 'selected-img slider-img' : "slider-img"}
            src={chapter.img} alt={"chapter img"}/>
            <br/>
            {chapter.title}
            </Link>
: <>
          <GImg className={index === currChapter ? 'selected-img slider-img no-link-slid' : "no-link-slid slider-img"}
            src={chapter.img} alt={"chapter img"}/>
            <br/>
            {chapter.title}</>}
        </SwiperSlide>
    ))}

  </Swiper>
  )
}
