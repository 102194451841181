import { useEffect, useState } from "react"
import ResponsiveGallery from "../../common/responsiveGallery/responsiveGallery"
import Popup from "../../common/popup/popup"
import './gallery.css'
import axiosApp from "../../common/api"
import GImg from "../../common/gImg"
import ClimbingBoxLoader from "react-spinners/ClimbingBoxLoader";

export default function Gallery() {
  const [images, setImages] = useState()
  const [showPopup, setShowPopup] = useState(false);
  const [currImg, setCurrImg] = useState()

  useEffect(() => { 
    axiosApp
              .get("/gallery", {})
              .then((response) => {
                console.log(response.data)
                let temp_imgs = []
                response.data[0].imgs.forEach(img => {
                  temp_imgs.push({img:img})
                });
                setImages(temp_imgs)
               
              })
              .catch((error) => {
                console.log(error);
              });
   
}, [])

function onImageClick (image)  {
  setCurrImg(image.img)
  setShowPopup(true)

}
  return (
    <div>
      {/* https://drive.google.com/file/d/1ez9aJ8j3LP9LW53uLSjJaBMx7rgd4Wrg/view?usp=drive_link
      <img src="https://lh3.googleusercontent.com/d/1ez9aJ8j3LP9LW53uLSjJaBMx7rgd4Wrg=w1000?authuser=0"></img> */}
      <Popup trigger={showPopup} setTrigger={setShowPopup}>
       <GImg className="open-img" src={currImg} alt={"gallery img"}/>
      </Popup>
      {images ? <ResponsiveGallery items={images} clickFunc={onImageClick}/> : 
      <div className="spinner"><ClimbingBoxLoader color="#85c7ee" /></div>}
    </div>
  )
}
